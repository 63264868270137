import React from "react"

import LocationBar from '../components/location-bar'
import SearchBar from '../components/search-bar'
import Toolbox from '../components/toolbox'

const IndexPage = ({data}) => {
  return (
      <main>
      <LocationBar />
      <SearchBar />
      <Toolbox />
      </main>
  )
}

export default IndexPage
