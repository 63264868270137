import React from 'react';

   import WeatherReport from './weather-report'
   import barStyles from './location-bar.module.css'

console.log({barStyles})
   const LocationBar = () => {
     return (
     <ul id='location-bar' className={barStyles.locationBar}>
       <WeatherReport city="Scottsdale" id='5313457' tz='-7'/>
       <WeatherReport city="Costa Mesa" id='5339840' tz='-7'/>
       <WeatherReport city="Wellington" id='2179538' tz='+12'/>
     </ul>
     )
   }


   export default LocationBar;
