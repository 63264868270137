import React from 'react';
import { useAsync } from 'react-use';

import { calcTime } from '../utils'
import wrStyle from './weather-report.module.css'

const WeatherReport = ({city, id, tz}) => {

  const weatherData = async (id) => {
    let API_KEY = process.env.GATSBY_OWM_API_KEY
    let owmURL = ` https://api.openweathermap.org/data/2.5/weather?id=${id}&units=imperial&APPID=${API_KEY}`
    const response = await fetch(owmURL)
    const result = await response.json()
    return result
  }

  let time = calcTime(tz)
  const state = useAsync(() => weatherData(id))
  let weather = state.value

  return (
      <li className={wrStyle.weatherReport}>
      {state.loading?
       <p>Gathering Info for {city}</p>
       : state.error?
       <p>Error...</p>
       : 
       <>
       <strong>{city}, {time}</strong>
       <p>{weather.weather[0].description}, {weather.main.temp}°</p>
       </>
      }
      </li>
  )
}

export default WeatherReport;
