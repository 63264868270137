import dayJS from 'dayjs';

export const calcTime = (offset) => {
  // this is taken from https://www.techrepublic.com/article/convert-the-local-time-to-another-time-zone-with-this-javascript/

  // get the current time for wherever htis code is being run
  const date = new Date();
  // return it as milliseconds
  const localTime = date.getTime();
  // get the UTC offset for our local time, converted to milliseconds
  const localOffset = date.getTimezoneOffset() * 60000;
  // get the current UTC
  const utc = localTime + localOffset;
  // get the time in milliseconds for our supplied timezone,
  // (given as the offset from UTC.)
  const cityTime = new Date(utc + (3600000*offset))

  return dayJS(cityTime).format('h:mm a');
}
