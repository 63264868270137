import React from 'react';
import { useAsync } from 'react-use';

import rkStyle from './random-kitty.module.css'

const RandomKitty = () => {

  const kittyData = async () => {
    const url = `https://api.thecatapi.com/v1/images/search`
    const response = await fetch(url, {
      headers: new Headers({
        'x-api-key': '73f08b27-f0e2-4494-b226-b829e48c8df9'
      })
    })
    const result = await response.json()
    return result
  }

  const state = useAsync(kittyData)
  console.log({kitty: state.value})


  return (
      <section className={rkStyle.cuteCat}>
      {state.loading?
       <div>Loading a cute kitty</div>
       : state.error?
       <div>Error...</div>
       : 
       <img src={state.value[0].url} alt='a cute kitty' />
      }
      </section>
  )
}

export default RandomKitty;
