import React from 'react';

const SearchBar = () => {
  let style = {
    overflow: "hidden",
    margin: "auto",
    padding: 0,
    width: "980px",
    height: "100px"
  }
  return (
    <section id='search-bar'style={{maxWidth: "100%", display: "flex", flexFlow: "column", padding:"2em"}}>
    <h2>Search the Web!</h2>
    <iframe src="https://duckduckgo.com/search.html?prefill=Search the web with DuckDuckGo&focus=yes"
  style={{...style}}
  frameborder="0">
    </iframe>
    </section>
  )
}

export default SearchBar;
