import React from 'react';
import { Link } from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'

import RandomKitty from './random-kitty'
import tbStyle from './toolbox.module.css'


const Toolbox = () => {
  let mailLink = `https://login.yahoo.com/?.src=ym&pspid=1197806870`
  return (
    <section id='toolbox' className={tbStyle.toolbox}>
      <div id='email'>
      <a href={mailLink} className={tbStyle.email} target="_blank" rel="noreferrer noopener">
        <FontAwesomeIcon icon={ faEnvelope } className={tbStyle.icon} />
        <p>CHECK YOUR EMAIL</p>
      </a>
      </div>
      <RandomKitty />
        <Link to="/message-angelica" className={tbStyle.messageLink}>MESSAGE ANGELICA</Link>
    </section>
  )
}

export default Toolbox;
